<template>
  <b-row>
    <b-row>
      <b-col lg="12" order="1" class="mb-1">
        <b-input-group prepend="Серийный ПК">
          <b-form-input v-model="code"></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" order="1" class="mb-1">
        <b-button variant="primary" @click="nextStep" :disabled="code.length < 12">Далее</b-button>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
export default {
  name: "Code",
  data() {
    return {
      code: '',
    }
  },
  methods: {
    nextStep() {
      this.$emit('nextStep', this.code);
    }
  }
}
</script>
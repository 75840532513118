<template>
  <highcharts :options="stockOptions" class="stock"></highcharts>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'GRAPH_DATA_CODES'
    ]),
    stockOptions() {
      return {
        chart: {
          type: 'spline'
        },
        xAxis: {
          categories: this.GRAPH_DATA_CODES.Months,
        },
        title: {
          text: 'Активации / блокировки'
        },
        series: [
          {
            name: 'Первичные',
            data: this.GRAPH_DATA_CODES.Actives,
            // data: this.actives,
          },
          {
            name: 'Отозваны',
            data: this.GRAPH_DATA_CODES.Revokeds,
          },
          {
            name: 'Блокированы',
            data: this.GRAPH_DATA_CODES.Blockeds,
          },
          {
            name: 'Перевыпущеные',
            data: this.GRAPH_DATA_CODES.Reissueds,
          }
        ]
      }
    }

  }
  ,
  mounted() {
    // console.log('all: ' + this.GRAPH_DATA_CODES)
    // console.log('Months ' + this.GRAPH_DATA_CODES.Months)
    // // console.log(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
    // console.log('Actives ' + this.GRAPH_DATA_CODES.Actives)
  }
}

</script>

<style scoped>
.stock {
  width: 100%;
  height: 93%;
  margin: 0 auto
}
</style>
<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Новая зявка">
        <b-card-text>
          <Code
              @nextStep='nextStep'
          />
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Code from "@/components/Code/Code"
import Toasts from '@/mixins/toasts';

export default {
  name: 'NewRequest',
  mixins: [Toasts],
  data() {
    return {}
  },
  components: {
    Code
  },
  methods: {
    nextStep(data) {
      this.axios.post('/code/set', {code: data})
          .then(response => {
            console.log(response.data)
            if (response.data.status === true) {
              this.$router.replace("/codes")
            }
          })
          .catch((error) => {
            this.showError('Ошибка запроса', error.message)
            // return Promise.reject(error);
          })
    },
  },
}
</script>

<template>
  <div>

    <div class="row">
      <div class="col-12 col-lg-12 col-xxl-12 d-flex">
        <div class="card flex-fill">
          <div class="card-header">
            <div class="card-actions float-right">
              <div class="dropdown show">
                <a href="#" data-toggle="dropdown" data-display="static">
                  <i class="align-middle" data-feather="more-horizontal"></i>
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="#">Все время</a>
                  <a class="dropdown-item" href="#">Месяц</a>
                  <a class="dropdown-item" href="#">Полгода</a>
                  <a class="dropdown-item" href="#">Год</a>
                </div>
              </div>
            </div>
            <h5 class="card-title mb-0">Лучшие партнеры</h5>
          </div>
          <table class="table table-hover my-0">
            <thead>
            <tr>
              <th>Наименование</th>
              <th class="d-none d-xl-table-cell">Предоплачено</th>
              <th class="d-none d-xl-table-cell">Установлено</th>
              <th>Status</th>
              <th class="d-none d-md-table-cell">Баланс</th>
            </tr>
            </thead>
            <tbody>
            <!--							<tr>-->
            <!--								<td>Project Apollo</td>-->
            <!--								<td class="d-none d-xl-table-cell">160</td>-->
            <!--								<td class="d-none d-xl-table-cell">60</td>-->
            <!--								<td><span class="badge bg-success">Done</span></td>-->
            <!--								<td class="d-none d-md-table-cell">50000</td>-->
            <!--							</tr>-->
            <!--							<tr>-->
            <!--								<td>Project Fireball</td>-->
            <!--								<td class="d-none d-xl-table-cell">3</td>-->
            <!--								<td class="d-none d-xl-table-cell">3</td>-->
            <!--								<td><span class="badge bg-danger">Cancelled</span></td>-->
            <!--								<td class="d-none d-md-table-cell">0</td>-->
            <!--							</tr>-->
            <!--							<tr>-->
            <!--								<td>Project Hades</td>-->
            <!--								<td class="d-none d-xl-table-cell">1</td>-->
            <!--								<td class="d-none d-xl-table-cell">0</td>-->
            <!--								<td><span class="badge bg-success">Done</span></td>-->
            <!--								<td class="d-none d-md-table-cell">3000</td>-->
            <!--							</tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import Toasts from '@/mixins/toasts';
// import Inquiries from '@/mixins/inquiries';

export default {
  name: 'HomePage',
  props: {},
  // mixins: [Toasts, Inquiries],

  data() {
    return {}
  },
  methods: {},

  computed: {},
  mounted() {
  },
  updated() {
  }
}
</script>

<style>
</style>

<template>
  <main class="d-flex w-100 h-100">
    <div class="container d-flex flex-column">
      <div class="row vh-100">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
          <div class="d-table-cell align-middle">

            <div class="text-center mt-4">
              <h1 class="h2">Добро пожаловать, мой друг</h1>
              <p class="lead">
                Войдите в свою учетную запись, чтобы продолжить
              </p>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="m-sm-4">
                  <div class="text-center">
                    <img src="@/assets/favicons/safari-pinned-tab.svg" alt="Charles Hall" class="img-fluid " width="100"
                         height="100"/>
                  </div>
                  <!--                  <form>-->
                  <div class="mb-3">
                    <label class="form-label">Имя пользователя</label>
                    <input class="form-control form-control-lg" type="text" placeholder="Введите ваш логин"
                           v-model="credentials.username"
                           v-on:keyup.enter="submit"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Пароль</label>
                    <input class="form-control form-control-lg" type="password" placeholder="Введите ваш пароль"
                           v-model="credentials.password"
                           v-on:keyup.enter="submit"
                    />
                    <!--                      <small>-->
                    <!--                        <a href="pages-reset-password.html">Forgot password?</a>-->
                    <!--                      </small>-->
                  </div>
                  <!--                    <div>-->
                  <!--                      <label class="form-check">-->
                  <!--                        <input class="form-check-input" type="checkbox" value="remember-me" name="remember-me" checked>-->
                  <!--                        <span class="form-check-label">-->
                  <!--													Remember me next time-->
                  <!--												</span>-->
                  <!--                      </label>-->
                  <!--                    </div>-->
                  <div class="text-center mt-3">
                    <!--                      <a href="index.html" class="btn btn-lg btn-primary">Sign in</a>-->
                    <button class="btn btn-lg btn-primary" @click="submit">Войти</button>
                    <!-- <button type="submit" class="btn btn-lg btn-primary">Sign in</button> -->
                  </div>
                  <!--                  </form>-->
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import auth from '@/auth'
import Toasts from "@/mixins/toasts";

export default {
  mixins: [Toasts],
  name: "Login",
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      error: ''
    }
  },
  methods: {
    submit: function () {
      let credentials = {
        username: this.credentials.username,
        password: this.credentials.password
      }
      auth.login(this, credentials, '/')
          .catch(error => {
            this.showError('Ошибка запроса', error.message)
          })
    }
  }
}
</script>

<template>
<!--  <b-container>-->
  <div>
    <b-row>
      <b-col cols="4" order="1">
        <b-col order="1">
          <Card
              :footerValue=precentAcive
              :value=countAcive
              footerTitle="% от прошлого месяца"
              image="calendar-month-fill"
              title="Новых в этом месяце"
          />
        </b-col>

        <b-col order="2">
          <Card
              :footerValue=onlines
              :value=DRV_REQ_CODES.Count
              footerTitle=" online сейчас"
              image="arrow-left-right"
              title="Запросы драйверов"
          />
        </b-col>
      </b-col>

      <b-col cols="8" order="2">
        <Chart/>
      </b-col>
    </b-row>

    <Partners/>
  </div>
<!--  </b-container>-->
</template>

<script>
import Partners from '@/components/Home/Partners.vue'
import Card from '@/components/Home/Card.vue'
import Chart from '@/components/Home/Chart.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Home',
  data() {
    return {}
  },
  components: {
    Partners,
    Card,
    Chart
  },
  computed: {
    ...mapGetters([
      'ONLINE_ITEMS',
      'DRV_REQ_CODES',
      'GRAPH_DATA_CODES'
    ]),
    countAcive() {
      if (this.GRAPH_DATA_CODES.Actives) {
        let act = this.GRAPH_DATA_CODES.Actives
        return act[act.length - 1]
      }
      return 0
    },
    precentAcive() {
      if (this.GRAPH_DATA_CODES.Actives) {
        if (this.GRAPH_DATA_CODES.Actives.length > 1) {
          let act = this.GRAPH_DATA_CODES.Actives
          return parseFloat(((act[act.length - 1] / act[act.length - 2]) * 100).toFixed(2))
        }
      }
      return 0
    },
    onlines() {
      return this.ONLINE_ITEMS == null ? 0 : this.ONLINE_ITEMS.length
    }
  },
  methods: {
    ...mapActions([
      'GET_COUNT_CODES',
      'GET_ONLINE_ITEMS'
    ]),
    counts: function () {
      this.GET_ONLINE_ITEMS()
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
          })
      this.GET_COUNT_CODES()
    }
  },
  cron: {
    time: 15000,
    method: 'counts'
  },
  mounted() {
    this.counts()
  }
}
</script>

<template>
  <b-card :title="'Пополнить баланс'">
    <b-card-text>
      <b-row>
        <b-col class="mb-1" lg="5" order="1">
          <b-input-group prepend="БИН/ИИН">
            <b-form-input v-model="bin" type="number"></b-form-input>
            <b-input-group-append is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="mb-1" lg="4" order="2">
          <b-input-group prepend="Сумма">
            <b-form-input v-model.number="money" type="number"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-1" lg="12" order="1">
          <b-button
              :disabled="bin.len < 12 || money === 0.0"
              variant="primary" @click="set">Пополнить
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-text>
      <b-table id="my-table" :busy="isBusy"
             :current-page="currentPage"
             :fields="fields"
             :filter="filter"
             :filter-included-fields="filterOn"
             @filtered="onFiltered"
             :items="BALANCE_ITEMS"
             :per-page="perPage"
             borderless
             hover
             outlined
             responsive="true"
             small
             striped>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="alignmake-middle"></b-spinner>
            <strong>Загрузка...</strong>
          </div>
        </template>
        <template #cell(Org.name)="data">
          <router-link :to="'/orgs/'+data.item.Org.id">{{ data.item.Org.name }}</router-link>
        </template>

      </b-table>
      <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="right" aria-controls="my-table"
                    first-number
                    last-number size="sm"></b-pagination>
    </b-card-text>


  </b-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Toasts from "@/mixins/toasts";

export default {
  name: 'Balance',
  mixins: [Toasts],
  data() {
    return {
      isBusy: false,
      bin: '',
      money: '',

      perPage: 18,
      currentPage: 1,
      totalRows: 0,

      filterOn: ['Org'],
      fields: [
        {key: 'Org.bin', label: 'БИН/ИИН', sortable: true},
        {key: 'Org.name', label: 'Наименование', sortable: true},
        {key: 'money', label: 'Сумма'},
      ],
    }
  },
  computed: {
    ...mapGetters([
      'BALANCE_ITEMS',
      'FILTER'
    ]),
    rows() {
      if (this.filter !== '') {
        return this.totalRows
      }
      return this.BALANCE_ITEMS == null ? 0 : this.BALANCE_ITEMS.length
    },
    filter: {
      get() {
        return this.FILTER;
      },
    }
  },
  methods: {
    ...mapActions([
      'SET_BALANCE',
      'GET_BALANCE_ITEMS'
    ]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    set: function () {
      // this.$emit('clickOkBtn')
      this.SET_BALANCE({bin: this.bin, money: this.money, type: 'add'})
          .catch((error) => {
            this.showError(error.message, error.response.data.error)
            if (error.response.status === 401) {
              this.$router.push('/logout')
            }
            stop()
          })
          .then((pesp) => {
            if (pesp.status === 200) {
              this.showInfo("Ok", this.bin  + ' пополнен ' + this.money)
              this.bin = ""
              this.money = ""
            }
          })

    }
  },
  mounted() {
    this.GET_BALANCE_ITEMS()
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push('/logout')
          }
        })
  }

}</script>

<template>
  <b-card :title="title">
    <b-row>
      <b-col>
        <div style="font-size: 2.5rem;">
          {{ value }}
        </div>
      </b-col>
      <b-col align="end">
        <div style="font-size: 2.5rem;">
          <b-icon :icon="image" class="rounded-circle bg-secondary p-2" variant="light"></b-icon>
        </div>
      </b-col>
    </b-row>
    <div class="mb-0">
      <span :class="[footerValue>0 ? 'text-success' : 'text-danger']">{{ footerValue }}</span>
      <span class="text-muted">{{ footerTitle }}</span>
    </div>
  </b-card>

</template>

<script>
export default {
  name: "Card",
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: 'image'
    },
    value: {
      type: Number,
      default: 0
    },
    footerTitle: {
      type: String,
      default: ''
    },
    footerValue: {
      type: Number,
      default: 0
    }
  }
}
</script>